import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConversationList() {
      return new Promise((resolve, reject) => {
        axios
          .get('https://graph.facebook.com/v9.0/107839714982898/conversations?platform=instagram&access_token=EAAI1E1zhlRgBAFRgDDQ4c8etwGFHTMXy3YK40hX5ZBsf4vBiElr0MlLaOXQsvxnxON1v5tZAiJvf1FuCoErak2tG4rCAgSSWiqwdhhlDNYUuTMJZBYTYFlZBSeZCPZAPv7lONNr7qvZCrY2s1a6MtbZCRMUJOWL6WVtA4W2bCVxcSgZCWfCXIRwWx')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMessageList(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://graph.facebook.com/v9.0/aWdfZAG06MTpJR01lc3NhZA2VUaHJlYWQ6MTc4NDE0NDk2NzE4ODQxMDM6MzQwMjgyMzY2ODQxNzEwMzAwOTQ5MTI4MjA1MDI5MjI2MDAyMzEx?fields=messages&access_token=EAAI1E1zhlRgBAFRgDDQ4c8etwGFHTMXy3YK40hX5ZBsf4vBiElr0MlLaOXQsvxnxON1v5tZAiJvf1FuCoErak2tG4rCAgSSWiqwdhhlDNYUuTMJZBYTYFlZBSeZCPZAPv7lONNr7qvZCrY2s1a6MtbZCRMUJOWL6WVtA4W2bCVxcSgZCWfCXIRwWx')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMessageDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`https://graph.facebook.com/v9.0/${id}?fields=message, from, to, is_unsupported&access_token=EAAI1E1zhlRgBAFRgDDQ4c8etwGFHTMXy3YK40hX5ZBsf4vBiElr0MlLaOXQsvxnxON1v5tZAiJvf1FuCoErak2tG4rCAgSSWiqwdhhlDNYUuTMJZBYTYFlZBSeZCPZAPv7lONNr7qvZCrY2s1a6MtbZCRMUJOWL6WVtA4W2bCVxcSgZCWfCXIRwWx`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/chats-and-contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/chat/chats/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
